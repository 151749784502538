import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { base_url } from "../../helper/axios";

const LazyImage = ({ className, imageUrl }) => {
  // Normalize the URL by replacing backslashes with forward slashes
  const normalizedImageUrl = imageUrl.replace(/\\/g, "/");

  const [imageSrc, setImageSrc] = useState(normalizedImageUrl);
  const [imageError, setImageError] = useState(false);

  const handleImageError = (error) => {
    setImageError(true);
    console.log(error);
  };

  return (
    <>
      {imageError ? (
        <LazyLoadImage
          effect="opacity"
          src="https://images.pexels.com/photos/4722571/pexels-photo-4722571.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Your image alt text"
          className={className}
          loading="lazy"
        />
      ) : (
        <LazyLoadImage
          effect="opacity"
          src={`${base_url}/images${imageSrc}`}
          alt="Your image alt text"
          className={className}
          loading="lazy"
          onError={(error) => handleImageError(error)}
        />
      )}
    </>
  );
};

export default LazyImage;
